import React, {useRef, useContext, useState, useEffect} from 'react';
import {AppContext} from './Provider';
import { useLocation } from "wouter";
import {LinkUrl} from '../App';
import useLocalStorage from './useLocalStorage';
import { FaChevronCircleLeft } from 'react-icons/fa';
import CanvasDraw from "react-canvas-draw";


const Firma = () => {
  const [state, setState] = useContext(AppContext)
  const [location, setLocation] = useLocation();
  let canvasFirma = useRef(null)
  const salvarFirma = () => {
    const salvar = canvasFirma.current.canvasContainer.children[1].toDataURL('image/png', 0.1)
    setState({...state, firma:salvar})
    setLocation('/home')
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-column align-items-center">
        <h2>Dibuje la firma en la cuadricula</h2>
      </div>
      <div>
        <CanvasDraw
          style={{
            boxShadow: "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
          }}
          canvasWidth='100vw'
          canvasHeight='150px'
          brushRadius='2'
          lazyRadius='0'
          ref={canvasFirma}
        />
      </div>
      <div className="btn-group d-flex">
        <button className="btn btn-primary" onClick={() => canvasFirma.current.clear()}>Limpiar</button>
        <button className="btn btn-warning" onClick={() => canvasFirma.current.undo()}>Deshacer</button>
        <button className="btn btn-success" onClick={() => salvarFirma()}>Salvar</button>
        <button className="btn btn-default" onClick={() => setLocation('/home')}>Atras</button>
      </div>
    </div>
  );
};

export default Firma;