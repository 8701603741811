import LogIn from './Login';

const LogPage = _ => {
  return (
    <div className="container-fluid d-flex flex-column justify-content-center imgLogin" style={{backgroundImage: 'url("img/fondoLogin.jpeg")'}}>
      <div className="d-flex flex-column align-items-center">
        <img src="img/logoA.png" alt="Confort Care Home" style={{width: '400px'}}/>
      </div>
      <LogIn />
    </div>
  );
}

export default LogPage;
