import React, {useRef, useContext, useState, useEffect} from 'react';
import {AppContext} from './Provider';
import { useLocation } from "wouter";
import {LinkUrl} from '../App';
import useLocalStorage from './useLocalStorage';
import Modal from 'react-modal';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const Home = () => {
  const [location, setLocation] = useLocation();
  const [state, setState] = useContext(AppContext)
  const [prendas, setPrendas] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [fotoAnterior, setFotoAnterior] = useState('')

  useEffect(() => {
    getFirma()
    getIdCargo()
  },[])

  const getIdCargo = () => {
    var formData = new FormData();
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('id_cliente', state.usuario[0].id_cliente)
    fetch(LinkUrl+'getIdCargo.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data.id_cargo === 0){
          alert('No hay datos para el cargo')
        }else{
          getIdPrenda(data[0].id_cargo)
        }
      })
      .catch(err => console.log(err))
  }

  const getIdPrenda = id => {
    var formData = new FormData();
    formData.append('id_cargo', id)
    formData.append('id_cliente', state.usuario[0].id_cliente)
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('nombre', state.usuario[0].nombre)
    fetch(LinkUrl+'getPrendas.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data.idArmado === 0){
          alert('No hay datos para el cargo')
        }else{
          setPrendas(data)
        }
      })
      .catch(err => console.log(err))
  }

  const getFirma = () => {
    var formData = new FormData();
    formData.append('cliente', state.usuario[0].id_cliente)
    formData.append('empleado', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    fetch(LinkUrl+'getFirma.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        mostrarFirma(data)
      })
      .catch(err => console.log(err))
  }

  const mostrarFirma = data => {
    if(data.length > 0){
      setFotoAnterior(data[0].foto)
      if(typeof state.firma === 'undefined'){
        setState({...state, firma: data[0].firma})
      }
    }
  }


  const goToPrendas = (prenda) => {
    setState({...state, prenda: prenda})
    setLocation('/prendas')
  }

  const comprobar = () => {
    var tallados = 0
    prendas.map((x) => {
      if(x.existe === '1'){
        tallados= tallados+1
      }
    })
    if(tallados === prendas.length){
      comprobarFirma()
    }else{
      alert('Faltan tallajes')
    }
  }

  const comprobarFirma = () => {
    if(state.firma === undefined || state.firma === null){
      alert('Favor firmar aceptando tallaje')
    }else if(state.foto === undefined || state.foto === null){
      alert('Favor tomar una foto para comprobar identidad')
    }else{
      guardarDatos()
    }
  }

  const guardarDatos = () => {
    var formData = new FormData();
    formData.append('cliente', state.usuario[0].id_cliente)
    formData.append('empleado', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('genero', state.usuario[0].genero)
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('firma', state.firma)
    fetch(LinkUrl+'saveEmpleadoTallado.php', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then((data) => {
          if(state.foto){
            guardarFoto()
          }else{
            alert('Tallaje Satisfactorio')
            setState([])
            setLocation('/')
          }
        })
        .catch(err => console.error(err))
  }

  const guardarFoto = () => {
    var formData = new FormData();
    formData.append('foto', state.foto)
    formData.append('empleado', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('cliente', state.usuario[0].id_cliente)
    fetch(LinkUrl+'saveFotoTallado.php', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then((data) => {
          alert('Tallaje Satisfactorio')
          setLocation('/')
        })
        .catch(err => console.error(err))
  }

  const logOut = async() => {
    setState([])
    await setLocation('/')
  }

  return (
    <div className="container home">
      <div className="d-flex flex-column align-items-center mt-4">
        <h1>{state.usuario[0].nombre}</h1>
        <h1>Cargo: {state.usuario[0].cargo}</h1>
        <div className="form-text">Seleccione una prenda para tallar.</div>
      </div>
      <div className="list-group mt-5">
        {prendas.map((prenda) => {
          return(
            <a href="javascript:void(0);" key={prenda.idArmado} className="list-group-item list-group-item-action" onClick={() => goToPrendas(prenda)}>
              {prenda.prendas}
              {prenda.existe === '1' ? <span className="badge rounded-pill bg-danger" style={{float: 'right'}}>Tallado</span> : null}
            </a>
          )
        })}
      </div>
      <div className="d-flex flex-row justify-content-around mt-3 mx-5">
        <div>
          <p>Firma:</p>
          <img src={state.firma} style={{width: '100%'}}/>
        </div>
        <div>
          <img src={state.foto} style={{width: '100%'}}/>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <a href="javascript:void(0);" onClick={() => setOpenModal(true)}>Ver Foto Anterior</a>
      </div>
      <div className="btn-group d-flex justify-content-center my-3 mx-5">
        <button type="button" className="btn btn-outline-secondary" onClick={() => logOut()}>Salir</button>
        <button type="button" className="btn btn-outline-primary" onClick={() => setLocation('/firma')}>Firmar</button>
        <button type="button" className="btn btn-outline-danger" onClick={() => setLocation('/camara')}>Foto</button>
        <button type="button" className="btn btn-outline-success" onClick={() => comprobar()}>Enviar</button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <a href="javascript:void(0);">Ver ayuda de tallas</a>
      </div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        onRequestClose={() => setOpenModal(false)}
      ><img src={'img/images/'+fotoAnterior} style={{width: '100%'}}/></Modal>
    </div>
  );
};

export default Home;