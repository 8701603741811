import React, {useRef, useContext, useState, useEffect} from 'react';
import {AppContext} from './Provider';
import { useLocation } from "wouter";
import {LinkUrl} from '../App';
import useLocalStorage from './useLocalStorage';
import Select from 'react-select'

const LogIn=()=> {
  let cedula = useRef(null)
  let empresa = useRef(null)
  const [location, setLocation] = useLocation();
  const [usuario, setUsuario] = useLocalStorage('usuario', '');
  const [state, setState] = useContext(AppContext)
  const [empresas, setEmpresas] = useState([])
  const [login, setLogin] = useState([])

  useEffect(() => {
    getEmpresas()
  },[])

  const entrar = (e) => {
    e.preventDefault()
    var formData = new FormData();
    formData.append('cedula', login.cedula)
    formData.append('empresa', login.empresa)
    fetch(LinkUrl+'login.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_empleado === 0){
          alert('No hay coincidencias')
        }
        if(data[0].cedula === login.cedula && data[0].id_cliente === login.empresa){
          setState({...state, usuario: data})
          setUsuario(data)
          setLocation('/home')
        }
      })
      .catch(err => console.log(err))
  }


  const getEmpresas = () => {
    fetch(LinkUrl+'getEmpresas.php', {
      method: 'GET'
    })
      .then(res => res.json())
      .then((data) => {
        setEmpresas(data)
      })
      .catch(err => console.log(err))
  }

  const handleChange = datos => {
    const value = datos === null ? '' : datos.value
    setLogin({...login, empresa: value})
  }

  return(
    <div className="container d-flex flex-column justified-content-center align-items-center">
      <div className="d-flex flex-column">
        <form onSubmit={(e) => entrar(e)}>
          <div className="mt-1">
            <label className="form-label">Cedula</label>
            <input className="form-control" type="number" ref={cedula} onChange={()=> {setLogin({...login, cedula: cedula.current.value})}}/>
          </div>
          <div className="mt-1">
            <label className="form-label">Empresa</label>
            <Select
              className="basic-single"
              classNamePrefix="Selecciona Empresa"
              defaultValue='Selecciona Empresa'
              isClearable={true}
              isSearchable={true}
              name="empresa"
              options={empresas}
              onChange={handleChange}
              placeholder='Empresa'
            />
          </div>
          <div className="d-flex flex-column align-items-end mt-3">
            <div>
              <button className="btn btn-outline-dark">Entrar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LogIn;