import React, {useRef, useContext, useState, useEffect} from 'react';
import {AppContext} from './Provider';
import { useLocation } from "wouter";
import {LinkUrl} from '../App';
import useLocalStorage from './useLocalStorage';
import { FaChevronCircleLeft } from 'react-icons/fa';
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const Camara = () => {
    const [state, setState] = useContext(AppContext)
    const [location, setLocation] = useLocation();
    function handleTakePhoto (dataUri) {
        setState({...state, foto: dataUri})
        setLocation('/home')
    }
    
    return (
        <div>
            <div>
                <Camera
                    onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                    isFullscreen = {false}
                    idealFacingMode = {FACING_MODES.ENVIRONMENT}
                    isImageMirror = {false}
                    idealResolution = {{width: 400, height: 400}}
                    imageCompression = {1}
                /> 
            </div>
            <div className="btn-group d-flex">
                <button className="btn btn-default" onClick={() => setLocation('/home')}>Atras</button>
            </div>
        </div>
    );
};

export default Camara;