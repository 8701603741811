import React, {useRef, useContext, useState, useEffect} from 'react';
import {AppContext} from './Provider';
import { useLocation } from "wouter";
import {LinkUrl} from '../App';
import useLocalStorage from './useLocalStorage';
import { FaChevronCircleLeft } from 'react-icons/fa';


var tallasHombre = [
  {label:"XXXXS", value:"(28)"},
  {label:"XXXS", value:"(30)"}, 
  {label:"XXS", value:"(32)"}, 
  {label:"XS", value:"(34)"}, 
  {label:"S", value:"(36)"}, 
  {label:"M", value:"(38)"},
  {label:"L", value:"(40)"},
  {label:"XL", value:"(42)"},
  {label:"XXL", value:"(44)"},
  {label:"XXXL", value:"(46)"}
]

var tallasMujer = [
  {label:"XXXS", value:"(3)"},
  {label:"XXS", value:"(4)"},
  {label:"XS", value:"(6)"}, 
  {label:"S", value:"(8)"}, 
  {label:"M", value:"(10)"}, 
  {label:"L", value:"(12)"}, 
  {label:"XL", value:"(14)"},
  {label:"XXL", value:"(16)"},
  {label:"XXXL", value:"(18)"},
  {label:"XXXXL", value:"(20)"},
  {label:"XXXXXL", value:"(22)"}
]


const Prendas = () => {
  let largoInput = useRef(null)
  let especialesInput = useRef(null)
  let observacionesInput = useRef(null)
  const [state, setState] = useContext(AppContext)
  const [location, setLocation] = useLocation();
  const [lista, setLista] = useState([])
  const [tallado, setTallado] = useState('')
  const [largo, setLargo] = useState('')
  const [especiales, setEspeciales] = useState('')
  const [observaciones, setObservaciones] = useState('')
  const [satel, setSatel] = useState('')
  const [nuevo, setNuevo] = useState(true)
  const [idtalla, setIdtalla] = useState('')

  useEffect(() => {
    tallas()
  }, [])

  const tallas = () => {
    checkPrenda()
    getSatelite()
    var tall = state.prenda.prendas.split("-")
    var sexo = tall[1].trim()
    if(sexo === 'Mujer'){
      setLista(tallasMujer)
    } 
    if(sexo === 'Hombre'){
      setLista(tallasHombre)
    }
  }

  const getSatelite = () => {
    var formData = new FormData();
    formData.append('idSatelite', state.prenda.idSatelite)
    fetch(LinkUrl+'getSatelite.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        setSatel(data[0].satelite)
      })
      .catch(err => console.log(err))
  }

  const checkPrenda = () => {
    var formData = new FormData();
    formData.append('nombre', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('idCliente', state.prenda.idCliente)
    formData.append('prendas', state.prenda.prendas)
    fetch(LinkUrl+'checkPrenda.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_tallas > 0){
          setNuevo(false)
          setTallado(data[0].talla)
          setLargo(data[0].largo)
          setEspeciales(data[0].especiales)
          setObservaciones(data[0].observaciones)
          setIdtalla(data[0].id_tallas)
        }
        if(data[0].id_tallas === 0){
          setNuevo(true)
        }
      })
      .catch(err => console.log(err))
  }

  const agregar = () => {
    var formData = new FormData();
    formData.append('idCliente', state.prenda.idCliente)
    formData.append('empleado', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('satelite', satel)
    formData.append('prenda', state.prenda.prendas)
    formData.append('talla', tallado)
    formData.append('largo', largo)
    formData.append('especiales', especiales)
    formData.append('observaciones', observaciones)
    formData.append('cantidad', state.prenda.cantidad)
    fetch(LinkUrl+'addPrenda.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data[0].result){
          setLocation('/home')
        }
      })
      .catch(err => console.log(err))
  }

  const modificar = () => {
    var formData = new FormData();
    formData.append('idTallas', idtalla)
    formData.append('idCliente', state.prenda.idCliente)
    formData.append('empleado', state.usuario[0].nombre)
    formData.append('cedula', state.usuario[0].cedula)
    formData.append('cargo', state.usuario[0].cargo)
    formData.append('satelite', satel)
    formData.append('prenda', state.prenda.prendas)
    formData.append('talla', tallado)
    formData.append('largo', largo)
    formData.append('especiales', especiales)
    formData.append('observaciones', observaciones)
    formData.append('cantidad', state.prenda.cantidad)
    fetch(LinkUrl+'editPrenda.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((data) => {
        if(data[0].result){
          setLocation('/home')
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div>
      <FaChevronCircleLeft
        size='2em'
        color='#f3238a'
        onClick={() => setLocation('/home')}
        style={{marginLeft: '0.5em', marginTop: '0.5em'}}
      />
      <div className="d-flex flex-column align-items-center mt-3 mx-2">
        <h1 align="center">{state.prenda.prendas}</h1>
        <h2>Cantidad: {state.prenda.cantidad}</h2>
        <h2>{tallado}</h2>
      </div>
      <div className="d-flex flex-row justify-content-center mt-4 flex-wrap mx-5">
        {lista.map((list, index) => {
          return(
            <button key={index} className="btn btn-outline-primary mx-2 my-2" style={{width: '90px'}} onClick={() => setTallado(list.label+'-'+list.value)}>{list.label}<br/><span>{list.value}</span></button>
          )
        })

        }
      </div>
      <div className="mt-4 mx-5 d-flex flex-column align-items-center">
        <div className="mb-3">
          <label className="form-label">Largo:</label>
          <input type="number" className="form-control" ref={largoInput}  value={largo} onChange={() => setLargo(largoInput.current.value)}/>
        </div>
        <div className="mb-3">
          <label className="form-label">Especiales:</label>
          <input type="text" className="form-control" ref={especialesInput} value={especiales} onChange={() => setEspeciales(especialesInput.current.value)}/>
        </div>
        <div className="mb-3">
          <label className="form-label">Observaciones:</label>
          <textarea type="text" className="form-control" ref={observacionesInput} value={observaciones} onChange={() => setObservaciones(observacionesInput.current.value)}/>
        </div>
        <div className="btn-group my-3">
          <button className="btn btn-outline-secondary" onClick={() => setLocation('/home')}>Volver</button>
          {nuevo ?
          <button className="btn btn-outline-success" onClick={() => agregar()}>Agregar</button>
          :
          <button className="btn btn-outline-warning" onClick={() => modificar()}>Modificar</button>
          }
        </div>
      </div>
    </div>
  );
};

export default Prendas;