import './App.css';
import { Route, Router } from "wouter"
import MyProvider from './components/Provider';
import LogPage from './components/LogPage'
import Home from './components/Home'
import Prendas from './components/Prendas'
import Firma from './components/Firma'
import Camara from './components/Camara'

const App = _ => {
  return (
      <MyProvider>
        <Route path="/" component={LogPage} />
        <Route path="/home" component={Home} />
        <Route path="/prendas" component={Prendas} />
        <Route path="/firma" component={Firma} />
        <Route path="/camara" component={Camara} />
      </MyProvider>
  );
}

// const LinkUrl = 'http://localhost:8080/ayd/'
const LinkUrl = 'ayd/'

export default App;
export {LinkUrl};
